.partner-prof-analytics {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .partner-prof-analytic-card {
        padding: 1rem;
        max-width: 150px;
        height: 150px;
        width: 100%;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .partner-prof-analytic-card-title {
            font-size: 1.8rem;
            font-weight: bolder;
            text-align: center;
        }

        .partner-prof-analytic-card-desc {
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
        }
    }
}