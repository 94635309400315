.fullfillTop {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 2rem;
}

.fullfillTitle {
    font-size: 2.2rem;
    font-weight: 700;
    width: 100%;
    max-width: 500px;
}

.fullfillDescTop {
    width: 100%;
    max-width: 500px;
    font-size: 1.2rem;
    margin: 1rem 0;
}

.fullfillDescBottom {
    font-size: 1.2rem;
}

.fullfillCateg {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
}

.fulllfillCateMain {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.fullfillCateheader {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.fullfillCateMainItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.fullfillCateTitle {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
}

.fullfillPrio {
    color: white;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.fullfillPrioContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00B050;
    margin-bottom: 2rem;
}

.fullfillPrioItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10rem;
}

.fullfillPriHeader {
    font-size: 1.8rem;
    font-weight: 600;
}

.fullfillPriItemDesc {
    font-size: 1.2rem;
}

.fullfillPriItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.ffItem img {
    width: 100%;
    max-width: 100px;
}

.fullfillRate {
    margin: 1rem;
}

.fullfillRateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.fullfillRateMain {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10rem;
}

.fullfillRateTop {
    text-align: center;
    margin-bottom: 1rem
}

.ffItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.ffItemTitle {
    margin-top: 1rem;
    font-weight: 600;
}

.ffItemRate {
    font-size: 1.2rem;
    color: #00B050;
    text-decoration: line-through;
    text-decoration-color: red;
}

.ffItemRateBtm {
    font-size: 1.2rem;
    color: #00B050;
}

.fullfillRateTop {
    font-size: 1.6rem;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .fullfillTop {
        padding: 1rem;
    }

    .fullfillright {
        margin: 1rem 0;
    }

    .fullfillPrioItems {
        grid-column-gap: 1rem;
    }

    .fullfillRateMain {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
    }
}