.riderrMain {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem;
}

.riderrLeft {
    margin: 1rem;
}

.riderrLeftImg img {
    max-width: 450px;
    width: 100%;
    height: auto;
}

.riderrLeftTitle {
    max-width: 450px;
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
}

.riderrLeftDesc {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 1rem 0;
}

.riderrRight {
    margin: 1rem;
}

.riderrRight img {
    width: 100%;
    max-width: 550px;
    height: auto;
}

.riderrOptions {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #00B050;
    color: white;
}

.riderrOptonsTitle {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.riderrOptionLeft img {
    max-width: 80px;
    width: 100%;
    height: auto;
}

.riderOptionTitle {
    font-size: 1.2rem;
    font-weight: 500;
}

.riderOptionDesc {
    width: 100%;
    max-width: 300px;
}

.riderrOption {
    display: flex;
    gap: 1rem;
    margin: 1rem;
}

.riderrOptionsItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8rem;
}

.howw {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.howwHeader {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 1rem;
}

.howwContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.howwItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.howwItem img {
    width: 100%;
    max-width: 100px;

}

.howwTitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1rem;
}

.howwDes {
    width: 200px;
    max-width: 100%;
    text-align: center;
}


@media only screen and (max-width: 600px) {
    .riderrLeft {
        margin: 1rem;
        padding-left: 0;
    }

    .riderrLeftImg {
        display: none;
    }

    .riderrOptionsItems {
        grid-template-columns: 1fr;
    }
}