.how {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 1rem;
}

.howMain {
    width: 100%;
    max-width: 400px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.howHeader {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    font-weight: 600;
}

.howTitle {
    margin: 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
}

.howDesc {
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.howImg {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.howImg img {
    width: 100%;
    max-width: 90px;
    height: auto;
}