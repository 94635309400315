.admin-sidebar {
    background: white;
    min-width: 240px;
    border-right: 0.4px solid #dadada;
    min-height: 100vh;
    user-select: none;

    .admin-side-top {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.4px solid #dadada;

        img {
            max-width: 100px;
            width: 100%;
            height: auto;
        }
    }

    .admin-side-center {
        margin: 1rem 0;
        padding: 0 1rem;
        border-bottom: 0.4px solid #dadada;

    }

    .dash-logout button {
        margin: 0 1rem;
        padding: 0 1rem;
        outline: none;
        background: transparent;
        border: 2px solid red;
        font-size: 1.4rem;
        border-radius: 22px;
        text-align: center;
        cursor: pointer;
    }
}