.partner-docs-top {
    font-size: 1.4rem;
}

.partner-docs-main {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;

    .partner-docs-item-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 1rem;
        max-width: 600px;
        width: 100%;

        .optn-head {
            font-weight: 500;
        }

        .partner-docs-item-detail {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
        }
    }
}

.partner-docs-uplds {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.partner-dash-doc-img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

@media only screen and (max-width: 600px) {
    .partner-docs-main {

        .partner-docs-item-details {
            display: grid;
            grid-template-columns: 1fr;
        }
    }
}