.partner-dash-main {
    background: #f7f8f9;
    display: flex;

    .partner-main-container {
        width: 100%;

        .partner-main {
            min-height: 100vh;
            padding: 1rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    .partner-side {
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
    }
}