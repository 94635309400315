.delMainRight img {
    width: 100%;
    max-width: 350px;
}

.delMain {
    margin: 1rem;
    margin-top: 4rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    #deliv-fleet-top {
        flex-direction: column-reverse;
    }

    #del-img {
        display: none;
    }
}