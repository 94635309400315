.partner-profile-top {
    font-size: 1.4rem;
    margin-bottom: 1rem
}

.partner-profile-main-top {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .partner-profile-main-top-left {
        img {
            max-width: 100px;
            width: 100%;
            border-radius: 50%;
            object-fit: contain;
        }
    }

    .partner-profile-main-top-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .partner-profile-main-top {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .partner-profile-main-top-right {
        align-items: center;
    }
}