.slidImg img {
    width: 100%;
    max-width: 350px;
    height: auto;
}

.sliderImg {
    overflow-x: hidden;
}

.slidImg {
    margin: 0 2rem;
}