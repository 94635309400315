.footer {
    display: flex;
    gap: 1rem;
    padding: 2rem 1rem 3rem 1rem;
    margin-top: 1rem;
    background: #F9F9F9;
}

.footerLeft {
    flex: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.footerLogo img {
    width: 100%;
    max-width: 200px;
    height: auto;
}

.footerSocial i {
    font-size: 2.5rem;
    margin: 0.6rem;
}

.footerRight {
    flex: 60%;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
}

.footerMain {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footerMainHeader {
    font-weight: 500;
    font-size: 1.2rem;
    color: #00B050;
}

.footerMainTitle {
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .footer {
        flex-wrap: wrap;
    }

    .footerLeft {
        flex: 1fr;
    }

    .footerRight {
        flex: 1fr;
    }
}