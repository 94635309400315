body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

#btn {
    outline: none;
    border: none;
    background: #00B050;
    color: white;
    border-radius: 22px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}

#btn2 {
    background: linear-gradient(270deg, #83FFBB -60.13%, #00B050 100%);
    border-radius: 48px;
    border: none;
    width: 140px;
    padding: 14px 8px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
}

#btn3 {
    border-radius: 48px;
    border: 1px solid #00B050;
    background: transparent;
    color: black;
    width: 100px;
    padding: 14px 8px;
    font-size: 1.2rem;
    cursor: pointer;
}

#btn4 {
    background: linear-gradient(270deg, #83FFBB -60.13%, #00B050 100%);
    border-radius: 48px;
    border: none;
    width: 140px;
    padding: 4px 6px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
}

#btn5 {
    border-radius: 48px;
    border: 1px solid #00B050;
    background: transparent;
    color: black;
    width: 100px;
    padding: 4px 6px;
    font-size: 1.2rem;
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}

input {
    background: transparent;
}