.sidebar-dropdown {
    .sidebar-dropdown-title {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .sidebar-dropdown-option {
        margin: 1rem 0;
        font-size: 1.2rem;
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: rgb(216, 253, 206);
        }
    }

}