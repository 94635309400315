.topbarLeft img {
    width: 100%;
    max-width: 150px;
    height: auto;
}

.topbar {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    padding: 1rem 9rem;
    -webkit-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.16);
    user-select: none;
}

.topbarRight {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
}

.topbarOption {
    cursor: pointer;
}

.topbarOptionTitle {
    margin-right: 6px;
    font-weight: 500;
}

#topOptn {
    display: none;
}

@media only screen and (max-width: 600px) {

    #topOptn {
        display: inline-block;
        font-size: 2rem;
        cursor: pointer;
    }

    .topbar {
        padding: 1rem;
        flex-direction: column;
        grid-template-columns: 1fr;
    }

    .topbarLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .topbarRight {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}