.faq {
    margin-top: 2rem;
}

.faqContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.faqTop {
    display: flex;
    justify-content: space-between;
}

.faqHeader {
    font-size: 2rem;
    font-weight: 600;
}

.faqItem {
    cursor: pointer;
    width: 85%;
    max-width: 1100px;
    padding: 1rem;
    border: 1px solid #00B050;
    margin: 1rem;
}

.faqTitle {
    font-size: 1.4rem;
    font-weight: 600;
}

.faqDesc {
    font-weight: 500;
    margin: 1rem 0;
}