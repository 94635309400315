.crtjob-container {
    width: 100%;
}

.textInput {
    font-size: 1.4rem;
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 1rem 0;
}

.textInput input {
    outline: none;
    border-radius: 4px;
}