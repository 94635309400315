.privacy {
    margin: 1.2rem 9rem;
    text-align: justify;
}

.privacyHeader {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.privacyLastUpdated {
    font-style: italic;
    margin-bottom: 1rem;
}

.privacySctnTitle {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.privacyPara {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
    .privacy {
        margin: 1.2rem;
    }
}