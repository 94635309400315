.partner-verif-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.partner-verif-top {
    width: 80vw;
    display: flex;
    justify-content: flex-end;
}

.partner-verif-top img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: contain;
}

.rider-verify {
    background: white;
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid black;
    border-left: 6px solid #00B050;
    padding: 1rem;
    margin: 1rem;
}

.rider-verify-aadhar {
    margin: 2rem 0;
}

.rider-verify-aadhar>span {
    font-size: 1.4rem;
    font-weight: 500;
}

.rider-verif-upload {
    width: 100%;
    margin-top: 1rem;
}

.rider-aadhar-front {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
}

.rider-aadhar-front>span {
    font-weight: 600;
    font-size: 1.2rem;
}

.rider-aadhar-back {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
}

.rider-verify-pan {
    margin: 4rem 0;
}

.rider-verify-pan>span {
    font-size: 1.4rem;
    font-weight: 500;
}

.rider-pan-front {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
}

.rider-verify-licence {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.rider-verify-licence>span {
    font-size: 1.4rem;
    font-weight: 500;
}

.rider-licence-front {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
}

.rider-aadhar-front>input {
    outline: none;
    border: none;
    border-bottom: 1px solid #00B050;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    width: 100%;
    max-width: 340px;
}

#rider-perm-addre {
    display: flex;
    align-items: center;
}

.upld-btn label {
    background-color: rgb(94, 0, 161);
    color: white;
    padding: 8px 16px;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
}

.upld-btn #file-chosen {
    margin-left: 0.3rem;
    font-family: sans-serif;
}