.aboutBld {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem;
    padding-bottom: 0;
}

.abtBldLeft {
    margin-top: 3rem;
    max-width: 500px;
    width: 100%;
}

.abtBldRight img {
    width: 100%;
    max-width: 500px;
}

.abtBldTitle {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.coreTeamTitle {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 4px 0;
}

.btBldDesc {
    line-height: 1.4;
}

.abtStatContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.abtStat {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    padding: 1rem;
    margin: 4rem 1rem;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.abtStatCenterTitle {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

.abtStatTop {
    font-size: 1.2rem;
    font-weight: 500;
}

.abtStatCenter {
    margin: 3rem 0;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
}

.abtStatMain {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.abtStatItem {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.abtStatItemTitle {
    font-weight: 500;
    margin: 1rem 0;
}

.abtStatBottom {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.abtBldDesc {
    margin-top: 1rem;
}

#abtStry {
    margin-bottom: 3rem;
}

.coreTeam {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coreTeamContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.coreTeamHeader {
    font-size: 1.4rem;
    font-weight: 700;
    color: #00B050;
}

.coreTeamItems {
    display: none;
    grid-template-columns: repeat(3, 1fr);
}

#teamOpen {
    grid-column-start: 1;
    grid-column-end: 4;
}

.coreTeamItem {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#rightArrow {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid white;
    border-radius: 100%;
    padding: 10px;
    background: white;
    cursor: pointer;
}

.coreTeamBox {
    position: relative;
    height: 160px;
    width: 140px;
    background: black;
}

.coreTeamBox img {
    height: 160px;
    width: 140px;
    object-fit: cover;
}

.coreTeamName {
    font-weight: 600;
    margin: 1rem 0 4px 0;
}

.coreTeamDesc {
    text-align: center;
    margin-top: 8px;
}

.coreTeamDetail {
    font-weight: 500;
    align-items: center;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .coreTeamItems {
        display: none;
        grid-template-columns: 1fr;
    }

    .coreTeamDesc {
        text-align: center;
        width: 100%;
        max-width: 350px;
    }

    .abtBldLeft {
        margin-top: 1rem;
    }

    #abtBld {
        flex-direction: column-reverse;
    }
}