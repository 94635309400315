.servicesMain {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2rem 0;
    padding: 1rem;
}

.servicesLeft {
    margin: 1rem;
}

.servicesLeftTitle {
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.servicesLeftDesc {
    font-size: 1.8rem;
    font-weight: 500;
}

.servicesLeftPara {
    max-width: 600px;
    font-size: 1rem;
    width: 100%;
    margin: 1rem 0;
}

.servicesRight {
    margin: 1rem;
}

.servicesRight img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.rider-Main {
    margin-top: 1rem;
}

.rider-Main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
}

.rider-LeftImg {
    margin-top: 2rem;
    padding-left: 6rem;
}

.rider-LeftImg img {
    max-width: 400px;
    width: 100%;
    height: auto;
}

.rider-LeftTitle {
    max-width: 600px;
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.rider-LeftDesc {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 1rem 0;
}

.rider-Right {
    margin: 1rem;
    margin-right: auto;
}

.rider-Right img {
    width: 100%;
    max-width: 400px;
    height: auto;
}

.rider-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
}