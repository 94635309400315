.admin-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dash-table-search input {
    outline: none;
    border: 1px solid #00B050;
    border-radius: 4px;
    font-size: 1rem;
    padding: 4px 8px;
}