.sidebar {
    background: #001529;
    padding-top: 1rem;
    min-width: 240px;
    min-height: 100vh;
}

.partner-side-btn {
    display: none;
}

.partner-side-top {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100px;
        width: 100%;
        height: auto;
    }
}

#partner-side-close {
    display: none;
}

@media only screen and (max-width: 600px) {
    .partner-side-btn {
        display: block;
    }

    .partner-side-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 1rem;

        #partner-side-close {
            display: block;
        }
    }
}