.admin-dash-main {
    background: #f7f8f9;
    display: flex;
    width: 100%;

    .admin-main-container {
        width: 100%;
        padding: 1rem;

        .admin-main {
            min-height: 100vh;
        }
    }
}

@media only screen and (max-width: 600px) {
    .admin-side {
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        display: none;
    }
}