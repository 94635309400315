.warehouseeMain {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem;
}

.warehouseeLeft {
    margin: 1rem;
}

.warehouseeLeftTitle {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 400px;
}

.warehouseeLeftDesc {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.warehouseeRight {
    margin: 1rem;
}

.warehouseeRight img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.warehouseeImg img {
    width: 100%;
    max-width: 400px;
    height: auto;
}

.partner {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.partnerTitle {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.partnerItem {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 4rem 2rem;
}

.partnerRightTitle {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0;
    width: 100%;
    max-width: 400px;
}

.partnerItemRight {
    margin-top: 1rem;
}

.partnerRightDesc {
    width: 100%;
    max-width: 400px;
    font-weight: 500;
    line-height: 1.6;
}

.partnerItemLeftImg img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.grow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #00B050;
    color: white;
}

.growLeft {
    margin: 1rem;
}

.growContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 1rem;
}

.growLeftTitle {
    font-size: 2.2rem;
    font-weight: 600;
}

.growLeftDesc {
    font-weight: 500;
    width: 100%;
    max-width: 400px;
    margin: 1rem 0;
}

.growRight img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

@media only screen and (max-width: 600px) {
    #partnerCostItem {
        flex-direction: column-reverse;
    }

    .warehouseeImg {
        display: none;
    }

    .partnerItem {
        margin: 0;
        margin-bottom: 2rem;
    }

    .partnerItemLeft {
        margin-top: 2rem;
    }
}