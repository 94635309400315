/* HOME MAIN */

#homeDelivImg {
    display: none;
}

#wareHouse {
    position: relative;
}

#connect1 {
    max-width: 75px;
    width: 100%;
    height: auto;
    position: absolute;
    right: 40%;
    top: -70%;
}

#exper {
    position: relative;
}

#connect2 {
    max-width: 18px;
    width: 100%;
    height: auto;
    position: absolute;
    right: 45%;
    top: -35%;
}

#delivery {
    position: relative;
}

#connect3 {
    max-width: 70px;
    width: 100%;
    height: auto;
    position: absolute;
    right: 50%;
    top: -80%;
}

.homeMain {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

.homeMainTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.homeMainTitle {
    font-size: 2.4rem;
    font-weight: 700;
    width: 100%;
    max-width: 620px;
}

.homeMainDesc {
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    max-width: 410px;
    margin: 1rem;
}

.homeMainImages {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    justify-content: space-evenly;
}

.homeMainImg {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.homeMainImg span {
    font-weight: 500;
    font-weight: 700;
    margin: 8px 0;
}

#home-main-img {
    align-self: flex-end;
    width: 100%;
    max-width: 400px;
    height: auto;
    z-index: 1;
}

/* HOME Brands Section */

.homeBrands {
    margin: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brandTitle {
    font-family: 'Raleway', sans-serif;
    text-align: center;
    margin: 1.4rem;
    font-size: 2rem;
    font-weight: 600;
}

.brandLogos {
    margin: 0 6px;
    padding: 0.5rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 1rem;
}

/* Home Feature Section */

.homeFeatureContainer {
    padding: 1rem;
    background: linear-gradient(270deg, #83FFBB -68.75%, #00B050 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.homeFeature {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6rem;
    color: white;
}

.featureItemTitle {
    font-size: 3.2rem;
    font-weight: 600;
}

.featureItemDesc {
    font-size: 1.2rem;
    font-weight: 400;
}

.featureItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Home Map Section */

.store {
    margin-top: 8rem;
    margin-bottom: 9rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.map {
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.rider {
    margin-bottom: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.whouse {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10rem;
}

.mapTitle {
    font-size: 2.8rem;
    font-weight: 700;
}

.mapDesc {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

.mapLoc {
    font-size: 1.2rem;
    width: 100%;
    max-width: 550px;
    margin: 1.2rem 0;
}

.mapLeft {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.mapBtns button {
    margin: 0 4px;
}

/* Home How Section */

.howHeader {
    text-align: center;
    margin-bottom: 0;
}

/* Home Why Section */

.why {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
}

.whyContainer {
    border: 1px solid #DCDBDB;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whyTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}

.whyMainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
}

.whyMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whyItem {
    display: flex;
    max-width: 500px;
    width: 100%;
    gap: 0.5rem;
}

.whyHeader {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    font-weight: 600;
}

.whyTopContent {
    margin: 1rem 0;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    max-width: 600px;
    width: 100%;
}

.whyItemRightTitle {
    font-size: 1.2rem;
    font-weight: 600;
}

.whyItemRightDesc {
    margin: 1rem 0;
    font-weight: 400;
}

@media only screen and (max-width: 600px) {
    /* HOME MAIN */

    .homeMainImages {
        display: none;
    }

    #homeDelivImg {
        display: block;
        width: 100%;
        max-width: 400px;
        height: auto;
    }

    .homeMain {
        padding: 1rem;
    }

    /* HOME Brands Section */

    .homeBrands {
        margin-top: 0;
    }

    .brandLogos {
        width: auto;
    }

    /* Home Map Section */
    .mapTitle {
        font-size: 2.2rem;
        font-weight: 600;
    }

    .mapDesc {
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 0.5rem;
    }

    .store {
        margin-top: 2rem;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 2rem 18px;
        margin-bottom: 3rem;
    }

    .map {
        flex-wrap: wrap;
        padding: 0 18px;
        margin-bottom: 3rem;
    }

    .mapRight {
        margin-top: 1rem;
    }

    .rider {
        flex-wrap: wrap;
        padding: 0 18px;
        margin-bottom: 1rem;
    }

    .whouse {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 0 18px;
        margin-bottom: 3rem;
    }


    .mapBtns button {
        margin-bottom: 1rem;
    }

    /* HOME Rider Section */

    .homeRider {
        position: relative;
    }

    .riderLeft {
        opacity: 30%;
    }

    .riderRight {
        margin: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .homeRider {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    /* Home Feature Section */

    .homeFeature {
        display: grid;
        grid-template-columns: 1fr;
    }

    .featureItem {
        margin-bottom: 1rem;
    }

    /* Home Why Section */

    .whyContainer {
        padding: 4px;
    }

    .whyMainContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
    }
}