.login {
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginContainer {
    margin-top: 3rem;
    padding: 2rem;
    border: 1px solid black;
    border-radius: 8px;
    border-left: 12px solid #00B050;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.loginTop {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.LoginHeader {
    font-size: 2.4rem;
    font-weight: 700;
}

.loginDesc {
    font-size: 1.2rem;
    font-weight: 400;
}

.loginBottom input {
    width: 300px;
    border: none;
    outline: none;
    border-bottom: 2px solid #00B050;
}

.InptTitle {
    font-weight: 500;
}

.loginEmail {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.loginBtn {
    margin: 1rem 0;
}