.rForm {
    max-width: 460px;
    width: 100%;
    border: 1px solid black;
    padding: 1rem;
    border-radius: 8px;
    border-left: 12px solid #00B050;
}

.rFormTitle {
    font-size: 1.2rem;
    margin: 1rem 0;
    font-weight: 600;
}

.rFormName {
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

.rFormName input {
    font-size: 1rem;
    border: none;
    outline: none;
    border-bottom: 1px solid #00B050;
    width: 450px;
    margin: 8px 0;
}

.rFormCity {
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

.rFormCity select {
    width: 450px;
    font-size: 1rem;
    margin: 8px 0;
    cursor: pointer;
    outline: none;
    border: none;
    text-shadow: 0 0 0 #000;
    border-bottom: 1px solid #00B050;
}

.rFormGender {
    margin: 1rem 0;
}

#rFormGenderTitle {
    font-size: 1.1rem;
    font-weight: 500;
}

.rFormGenderInput {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    margin: 8px 0;
}

@media only screen and (max-width: 600px) {
    .rForm {
        max-width: 290px;
        width: 100%;
    }

    .rFormName input {
        border: none;
        outline: none;
        border-bottom: 1px solid #00B050;
        width: 250px;
        margin: 8px 0;
    }

    .rFormCity select {
        width: 250px;
    }

}